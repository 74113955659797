import React, { useEffect, useState } from "react";
import {
  generateGameClockChoicesNBA,
  generateGameClockChoicesNcaa,
  generateGameClockChoicesWNBA,
} from "../../utils/gameClockUtils";
import Utils from "../../utils/Utils";
import InputRange from "react-input-range";
import SliderRangeDualFloat from "../slider-range/SliderRangeDualFloat";
import Tooltip from "../tooltip/Tooltip";
export const defGameClockDuration = {
  nba: 2880,
  ncaa: 2400,
  college_mens: 2400,
  college_womens: 2400,
  wnba: 2400,
};
export const orderedHeaderHistorical = [
  "date",
  "clock",
  "team",
  "opponent",
  "score",
  "sq_score",
  "live_spread",
  "live_total",
  "pred_spread",
  "pred_total",
  "pregame_spread",
  "pregame_total",
  "actual_final_score_diff",
  "actual_final_total",
  "live_total_win",
  "live_spread_win",
  "final_team_score",
  "final_opponent_score",
  "Live Spread Result",
  "Live Total Result",
  "season",
  "home_score_final",
  "away_score_final",
  "home_team",
  "away_team",
  "actual_minus_sq_margin",
  "actual_minus_sq_total",
  "live_spread_win_opp",
  "live_spread_win_team",
  "live_spread_win_x",
  "live_spread_win_y",
  "live_total_win_over",
  "live_total_win_under",
  "result_opponent_cover",
  "result_over",
  "result_team_cover",
  "result_under",
  "live_proj_game_market_total_diff",
  "live_proj_spread_market_spread_diff",
  "live_opp_ppp",
  "live_opp_ppp_live_opp_sq_ppp_diff",
  "live_opp_ppp_pre_opp_ppp_diff",
  "live_opp_sq_ppp",
  "live_opp_sq_ppp_pre_opp_ppp_diff",
  "live_pace",
  "live_pace_pre_pace_diff",
  "live_ppp",
  "live_ppp_live_sq_ppp_diff",
  "live_ppp_pre_ppp_diff",
  "live_sq_ppp",
  "live_sq_ppp_pre_ppp_diff",
  "location",
  "period",
  "minutes",
  "seconds",
  "seconds_remaining",
  "margin",
  "sq_margin",
  "team_pts",
  "opponent_pts",
  "team_sq_pts",
  "opponent_sq_pts",
  "total",
  "sq_total",
  "order",
  "play_id",
  "pregame_opp_ortg",
  "pregame_ortg",
  "pregame_pace",
  "end_first",
  "end_third",
  "is_halftime",
  "is_overtime",
];
export const defaultFiltersCheckboxes = [
  {
    category: "Time",
    options: [
      { name: "Game Clock", id: "seconds_remaining" },
      { id: "location-away", name: "Away" },
      { id: "location-home", name: "Home" },
      { id: "isOverTime", name: "Over Time" },
      { id: "isHalfTime", name: "HalfTime" },
      { id: "isEndFirst", name: "End 1st" },
      { id: "isEndThird", name: "End 3rd" },
      { name: "Minimum Time Elapsed", id: "minimumTimeElapsed" },
    ],
  },
  {
    category: "Points",
    options: [
      { name: "SQ Points", id: "team_sq_pts" },
      { name: "Opponent SQ Points", id: "opponent_sq_pts" },
      { name: "Actual Points", id: "team_pts" },
      { name: "Actual Opponent Points", id: "opponent_pts" },
      { name: "SQ Point Diff", id: "sq_margin" },
      { name: "Actual Point Diff", id: "margin" },
      { name: "SQ Total", id: "sq_total" },
      { name: "Actual Total", id: "total" },
      { name: "Actual Point Diff minus SQ Point Diff", id: "actual_minus_sq_margin" },
      { name: "Actual Total minus SQ Total", id: "actual_minus_sq_total" },
    ],
  },
  {
    category: "Per Possession",
    options: [
      { name: "Team Live PPP", id: "live_ppp" },
      { name: "Team Live SQ PPP", id: "live_sq_ppp" },
      { name: "Opp Live PPP", id: "live_opp_ppp" },
      { name: "Opp Live SQ PPP", id: "live_opp_sq_ppp" },
    ],
  },
  {
    category: "Pace",
    options: [
      { name: "Pregame Pace", id: "pregame_pace" },
      { name: "Live Pace", id: "live_pace" },
    ],
  },
  {
    category: "O/U & Spreads",
    options: [
      { name: "Pregame Spread", id: "pregame_spread" },
      { name: "Pregame Total", id: "pregame_total" },
      { name: "Live Spread", id: "live_spread" },
      { name: "Live Total", id: "live_total" },
      { name: "Live Proj Spread", id: "pred_spread" },
      { name: "Live Proj Spread v Market Spread Diff", id: "live_proj_spread_market_spread_diff" },
      { name: "Live Proj Game Total", id: "pred_total" },
      { name: "Live Proj Game Total v Market Total Diff", id: "live_proj_game_market_total_diff" },
      { id: "isMax", name: "Max" },
    ],
  },
  {
    category: "% Diff",
    options: [
      { name: "Live Pace vs Pre Pace %Diff", id: "live_pace_pre_pace_diff" },
      { name: "Team Live PPP vs Live SQ PPP %Diff", id: "live_ppp_live_sq_ppp_diff" },
      { name: "Team Live PPP vs Pre PPP %Diff", id: "live_ppp_pre_ppp_diff" },
      { name: "Opp Live PPP vs Pre PPP %Diff", id: "live_opp_ppp_pre_opp_ppp_diff" },
      { name: "Opp SQ Live PPP vs Pre PPP % Diff ", id: "live_opp_sq_ppp_pre_opp_ppp_diff" },
      { name: "SQ Live PPP vs Pre PPP % Diff", id: "live_sq_ppp_pre_ppp_diff" },
      { name: "Opp Live PPP vs Live SQ PPP %Diff", id: "live_opp_ppp_live_opp_sq_ppp_diff" },

      { name: "Game SQ Live PPP vs Game Live PPP %Diff", id: "live_game_ppp_live_game_sq_ppp_diff" },
      { name: "Game SQ Live PPP vs Game Pre PPP %Diff", id: "live_game_sq_ppp_pre_game_ppp_diff" },
      // { name: "Grade Off Custom Spread", id: "gradeOff" },
    ],
  },
];
export const initialDefaultFilters = [
  "seconds_remaining",
  // "team_sq_pts",
  // "opponent_sq_pts",
  // "team_pts",
  // "opponent_pts",
  // "sq_margin",
  // "margin",
  // "sq_total",
  // "total",
  "actual_minus_sq_margin",
  "actual_minus_sq_total",
  "pregame_spread",
  "pregame_total",
  "live_spread",
  "live_total",
  // "pred_spread",
  "live_proj_spread_market_spread_diff",
  // "pred_total",
  "live_proj_game_market_total_diff",
  // "pregame_pace",
  // "live_pace",
  "live_pace_pre_pace_diff",
  // "location-away",
  // "location-home",
  // "isOverTime",
  "isHalfTime",
  "isEndFirst",
  "isEndThird",

  // "isMax",
  // "live_ppp",
  // "live_sq_ppp",
  "live_ppp_live_sq_ppp_diff",
  "live_ppp_pre_ppp_diff",
  // "live_opp_ppp",
  // "live_opp_sq_ppp",
  "live_opp_ppp_live_opp_sq_ppp_diff",
  "live_opp_ppp_pre_opp_ppp_diff",
  "live_sq_ppp_pre_ppp_diff",
  "live_opp_sq_ppp_pre_opp_ppp_diff",
  // "minimumTimeElapsed",
  // "gradeOff",
];
export const LQTSliderTooltip = {
  // seconds_remaining: "",
  // gameClock: "",
  team_sq_pts: "Expected Points For",
  opponent_sq_pts: "Expected Points Against",
  team_pts: "Actual Points For the Team",
  opponent_pts: "Opponent Actual Points",
  sq_margin: "SQ Points Minus Points",
  margin: "Actual Points minus Actual Opponent Points",
  sq_total: "Sum of SQ Points and Opponent SQ Points",
  total: "Sum of Actual Points and Actual Opponent Points",
  actual_minus_sq_margin:
    "Amount by which the Team's Actual Point Differential Exceeds the ShotQuality Point Differential.",
  actual_minus_sq_total:
    "Amount by which the Actual Total Points Scored by Both Teams Exceeds the total ShotQuality Points.",
  pregame_spread: "Closing Consensus Pregame Point Spread",
  pregame_total: "Closing Consensus Pregame Total",
  live_spread: "Live Consensus Point Spread",
  live_total: "Live Consensus Point Total",
  pred_spread: "Adjusted market spread lines based on team and opponent shooting luck per ShotQuality",
  live_proj_spread_market_spread_diff:
    "The difference between the live projected spread and the actual live spread, at that time.",
  pred_total: "Adjusted market total lines based on team and opponent shooting luck per ShotQuality.",
  live_proj_game_market_total_diff:
    "The difference between the live projected total and the actual live total, at that time.",
  pregame_pace: "Projected Pregame Pace (Average of Both Teams in Previous Games)",
  live_pace: "The Game's Actual Pace",
  live_pace_pre_pace_diff: "Percentage by which the Game's Actual Pace Exceeds the Pregame Pace",
  // "location-away": "",
  // "location-home": "",
  // isOverTime: "",
  // isHalfTime: "",
  // isEndFirst: "",
  // isEndThird: "",
  isMax: "Represents Largest Difference between Live Proj Spread and Live Spread under Current Filtered Criteria",
  live_ppp: "Actual Points Per Possession For in the In-Game",
  live_sq_ppp: "ShotQuality Expected Points Per Possession in the In-Game",
  live_ppp_live_sq_ppp_diff:
    "Percentage by which the Team is Underperforming its In-Game Expectations. 10% means the Team is Underperforming & -5% means the Team is Overperforming",
  live_ppp_pre_ppp_diff:
    "Percentage by which the Team is Overperforming its Pre-Game Expectations. 10% means the Team is Overperforming & -5% means the Team is Underperforming",
  live_opp_ppp: "Actual Opponent Points Per Possession in the In-Game",
  live_opp_sq_ppp: "Opponent Expected Points Per Possession in the In-Game",
  live_opp_ppp_live_opp_sq_ppp_diff:
    "Percentage by which the Opponent is Underperforming its In-Game Expectations. 10% means the Opponent is Underperforming & -5% means the Opponent is Overperforming",
  live_opp_ppp_pre_opp_ppp_diff:
    "Percentage by which the Opponent is Overperforming its Pre-Game Expectations. 10% means the Opponent is Overperforming & -5% means the Opponent is Underperforming",
  live_sq_ppp_pre_ppp_diff:
    "Percentage by which the Team's In-Game Expectations exceed its Pre-Game Expectations. 10% means the Team is Underperforming & -5% means the Team is Overperforming",
  live_opp_sq_ppp_pre_opp_ppp_diff:
    "Percentage by which the Opponent's In-Game Expectations exceed its Pre-Game Expectations. 10% means the Opponent is Underperforming & -5% means the Opponent is Overperforming",
  minimumTimeElapsed: "Minimum Time Elapsed in the Game",
  // gradeOff: "",
};
export const yearOptions = {
  nba: [
    { value: "2024", label: "2024" },
    { value: "2025", label: "2025" },
  ],
  ncaa: [
    { value: "2024", label: "2024" },
    { value: "2025", label: "2025" },
  ],
  wnba: [
    { value: "2023", label: "2023" },
    { value: "2024", label: "2024" },
    // { value: "2025", label: "2025" },
  ],
};
export const leagueOptions = [
  { value: "ncaa", label: "NCAA" },
  { value: "nba", label: "NBA" },
  { value: "wnba", label: "WNBA" },
];
export const sqAlertsDefaults = {
  email: "",
  phone: "",
  emailChecked: false,
  phoneChecked: false,
};

export const sqAlertsReducer = (state, action) => {
  switch (action.type) {
    case "email":
      return { ...state, email: action.payload };
    case "phone":
      return { ...state, phone: action.payload };
    case "emailChecked":
      return { ...state, emailChecked: action.payload };
    case "phoneChecked":
      return { ...state, phoneChecked: action.payload };
    default:
      return state;
  }
};

export function Slider({
  id,
  name,
  vals,
  setVals,
  percentage,
  resetFilters,
  startingMinValue,
  startingMaxValue,
  queryParams,
}) {
  const [defaultVals, setDefaultVals] = useState(vals);
  const [userVals, setUserVals] = useState(vals);
  useEffect(() => {
    setUserVals(vals);
  }, [vals]);

  const roundToNearest = (value, step) => {
    let updateValue = { min: value.min, max: value.max };

    // if val step is not 1, then round to 1 decimal place
    if (step && step !== 1) {
      updateValue = {
        min: value.min?.toFixed(2),
        max: value.max?.toFixed(2),
      };
    }
    return updateValue;
  };
  const handleDrag = (newValue, name, id) => {
    let updateValue = roundToNearest(newValue, vals.step);
    let newVal = { name, id, ...updateValue };
    setUserVals({ ...newVal });
  };

  const handleChangeComplete = (e) => {
    let newVal = { name, id, min: e[0], max: e[1] };
    queryParams.set(id + "_min", e[0]);
    queryParams.set(id + "_max", e[1]);
    setVals({ ...newVal });
  };

  return (
    <div className="px-3 text-center">
      <label
        htmlFor={id}
        style={{
          minHeight: "2rem",
          fontSize: "0.7rem",
        }}
      >
        {LQTSliderTooltip[id] ? (
          <Tooltip label={vals.display} text={LQTSliderTooltip[id]} classNames="bottom p-2" delay={1100} />
        ) : (
          vals.display
        )}
        {/* {vals.display} */}
      </label>
      <div className="mt-1">
        {/* <InputRange
          minValue={defaultVals.min}
          maxValue={defaultVals.max}
          value={userVals}
          onChange={(value) => handleDrag(value, name, id)}
          onChangeComplete={(value) => handleChangeComplete(value, name, id)}
          step={vals.step ? vals.step : 1}
        /> */}
        <SliderRangeDualFloat
          min={defaultVals.min}
          max={defaultVals.max}
          handleChangeComplete={handleChangeComplete}
          id={vals.display}
          step={vals.step}
          formatValue={(value) => (percentage ? `${(value * 100)?.toFixed(0)}%` : value)}
          formatHandle={(value) => (percentage ? `${(value * 100)?.toFixed(0)}%` : value)}
          resetFilters={resetFilters}
          startingMinValue={startingMinValue}
          startingMaxValue={startingMaxValue}
        />
      </div>
    </div>
  );
}

export const SliderWithTextLabels = ({
  defaultRange,
  vals,
  setVals,
  league,
  period,
  resetFilters,
  startingMinValue,
  startingMaxValue,
  queryParams,
}) => {
  let [gameClockChoice, setGameClockChoice] = useState([]);
  const [range, setRange] = useState(null);
  const clockRange = defaultRange.filter((item) => item.id === "seconds_remaining");
  useEffect(() => {
    let gameClock = [];
    if (league === "ncaa") {
      gameClock = generateGameClockChoicesNcaa(clockRange[0]["max"]);
    } else if (league === "nba") {
      gameClock = generateGameClockChoicesNBA(clockRange[0]["max"]);
    } else if (league === "wnba") {
      gameClock = generateGameClockChoicesWNBA(clockRange[0]["max"]);
    }
    gameClock = gameClock.reduce((obj, item) => {
      const [key, value] = Object.entries(item)[0];
      obj[key] = value;
      return obj;
    }, {});
    setGameClockChoice(gameClock);
    setRange({ min: 0, max: Object.keys(gameClock).length - 1 });
  }, [league]);

  const choicesArray = Object.keys(gameClockChoice);

  if ((startingMaxValue || startingMinValue) && choicesArray.length > 0) {
    //reverse look up for starting values coming from url
    const reverseGameClockChoice = Utils.swapObjectKeyValue(gameClockChoice);
    const gameClockKeys = Object.keys(reverseGameClockChoice);
    console.log("reverseGameClockChoice", reverseGameClockChoice);
    console.log("gameClockKeys", gameClockKeys);
    if (startingMinValue) {
      let startingMinValueIndex = reverseGameClockChoice[startingMinValue];
      console.log("startingMinValueIndex", startingMinValueIndex);
      if (startingMinValueIndex === undefined) {
        const closestToStartingMinValue = Utils.findClosestNumber(gameClockKeys, startingMinValue);
        console.log("closestToStartingMinValue", closestToStartingMinValue);
        startingMinValueIndex = reverseGameClockChoice[closestToStartingMinValue];
      }
      startingMinValue = choicesArray.findIndex((v) => v === startingMinValueIndex);
    }
    if (startingMaxValue) {
      let endingMinValueIndex = reverseGameClockChoice[startingMaxValue];
      if (endingMinValueIndex === undefined) {
        const closestToEndingMinValue = Utils.findClosestNumber(gameClockKeys, startingMaxValue);
        endingMinValueIndex = reverseGameClockChoice[closestToEndingMinValue];
      }
      startingMaxValue = choicesArray.findIndex((v) => v === endingMinValueIndex);
    }
  }
  const handleDrag = (newValue) => {
    setRange(newValue);
  };
  const handleUserRangeChange = (range) => {
    let value = {
      id: "seconds_remaining",
      min: gameClockChoice[choicesArray[range[1]]],
      max: gameClockChoice[choicesArray[range[0]]],
      game_clock_min: range[1],
      game_clock_max: range[0],
      name: "game_time",
    };
    queryParams.set("seconds_remaining_min", gameClockChoice[choicesArray[range[1]]]);
    queryParams.set("seconds_remaining_max", gameClockChoice[choicesArray[range[0]]]);

    setVals({ ...value });
  };
  let tickValues = [0, Math.floor(choicesArray.length / 2), choicesArray.length - 1];
  if (league === "nba" || league === "wnba") {
    tickValues = [
      Math.floor((choicesArray.length * 1) / 4) - 1,
      Math.floor((choicesArray.length * 2) / 4) - 1,
      Math.floor((choicesArray.length * 3) / 4) - 1,
      choicesArray.length - 1,
    ];
  }
  return (
    <>
      {gameClockChoice && range && (
        <div className="px-3 text-center">
          <label
            htmlFor={"gameClock"}
            style={{
              minHeight: "2rem",
              fontSize: "0.7rem",
            }}
          >
            Game Clock
          </label>
          <div className="mt-1">
            {/* <InputRange
              maxValue={choicesArray.length - 1}
              minValue={0}
              formatLabel={(value) => `${choicesArray[value]}`}
              value={range}
              onChange={handleDrag}
              onChangeComplete={(value) => handleUserRangeChange(value)}
              id="gameClock"
            /> */}
            <SliderRangeDualFloat
              min={0}
              max={choicesArray.length - 1}
              handleChangeComplete={(value) => handleUserRangeChange(value)}
              formatValue={(value, idx) => {
                //Simon wanted the middle part of the slider to be labeled "H" for NCAA
                if (league === "ncaa" && idx === 1) return "H";
                return `${choicesArray[value].split(" ")[0]}`;
              }}
              formatHandle={(value) => `${choicesArray[value]}`}
              id={"Game Clock"}
              resetFilters={resetFilters}
              ticks={tickValues.length}
              ticksValues={tickValues}
              startingMinValue={startingMinValue}
              startingMaxValue={startingMaxValue}
            />
          </div>
        </div>
      )}
    </>
  );
};

export function OneSlider({ id, name, vals, setVals, resetFilters, constantMax, startingMinValue, queryParams }) {
  const [defaultVals, setDefaultVals] = useState(vals);
  const [userVals, setUserVals] = useState(vals);

  useEffect(() => {
    setUserVals(vals);
  }, [vals]);

  const handleDrag = (newValue, name, id) => {
    let newVal = { name, id, ...newValue };
    setUserVals({ ...newVal });
  };

  // const handleChangeComplete = (newValue, name, id) => {
  //   let newVal = { name, id, ...newValue };
  //   setVals({ ...newVal });
  // };
  const handleChangeComplete = (e) => {
    let newVal = { name, id, min: e[0], max: constantMax };
    queryParams.set(id + "_min", e[0]);
    setVals({ ...newVal });
  };

  return (
    <div className="px-3 text-center">
      <label
        htmlFor={id}
        style={{
          minHeight: "2rem",
          fontSize: "0.7rem",
        }}
      >
        {vals.display}
      </label>
      <div className="mt-3">
        <SliderRangeDualFloat
          min={defaultVals.min}
          max={constantMax}
          handleChangeComplete={handleChangeComplete}
          id={vals.display}
          step={vals.step}
          disableSlider="right"
          resetFilters={resetFilters}
          startingMinValue={startingMinValue}
        />
      </div>
    </div>
  );
}

// Helper function for filtering active saved filters
export const getActiveSavedFilters = (pFilters, pNotifications) => {
  const activeFilters = JSON.parse(JSON.stringify(pFilters)); // Deep clone to avoid mutations

  Object.entries(pFilters).forEach(([league, filters]) => {
    Object.entries(filters).forEach(([filterName, filterData]) => {
      if (pNotifications[league]?.[filterName]?.is_active === false) {
        delete activeFilters[league][filterName];
      }
    });
  });
  console.log("pFilters", pFilters);
  console.log("activeFilters", activeFilters);
  return activeFilters;
};

export const mergeLiveQueryData = (data) => {
  return Object.values(
    data.reduce((acc, item) => {
      // If we already have this game_id
      if (acc[item.game_id]) {
        // Append the new filterName to existing ones
        acc[item.game_id].filterName = acc[item.game_id].filterName + ", " + item.filterName;

        // Keep the most recent entry based on updated_at
        const existingDate = new Date(acc[item.game_id].updated_at);
        const newDate = new Date(item.updated_at);
        if (newDate > existingDate) {
          // Update all other fields with the most recent data
          acc[item.game_id] = {
            ...item,
            filterName: acc[item.game_id].filterName, // Keep the concatenated filterName
          };
        }
      } else {
        // First time seeing this game_id
        acc[item.game_id] = { ...item };
      }
      return acc;
    }, {})
  );
};
