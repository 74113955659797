import "../styles/App.scss";
import "../components/articleBlock/articleBlock.scss";
import "../components/highlightsPoint/highlightsPoints.scss";
import "../components/recentTweets/recentTweets.scss";
import "../components/tables/tables.scss";
import "./post/blog-post.scss";
import { authGetRequest, authPostRequest } from "../utils/authGetRequest";
import "../components/predictiveTools/predictiveTools.scss";
import Utils from "../utils/Utils";
import { useContext, useReducer, useEffect, useState, useRef, useMemo, useCallback } from "react";
import { CookiesProvider } from "react-cookie";
import { AppContext, AppContextWrapper } from "../context/state";
import { getActiveSavedFilters } from "../components/LiveQueryConstants";
import { getAppService } from "../utils/getAppService";
import { ApolloProvider } from "@apollo/client";
import client from "../utils/apolloClient";
import Script from "next/script";
import useUTMParams from "../hooks/useUTMParams";
import Head from "next/head";
import { useRouter } from "next/router";
import { destroyCookie } from "nookies";
import { BsAlarmFill } from "react-icons/bs";
import * as ga from "../lib/GoogleAnalytics";
import dynamic from "next/dynamic";

// const PlausibleProvider = dynamic(() => import("next-plausible"), { ssr: false });
import PlausibleProvider from "next-plausible";
import { toast } from "react-toastify";

// Constants
const NOTIFICATION_CONFIG = {
  POLLING_INTERVAL: 20000,
  TOAST_DURATION: 7000,
  TOAST_DELAY_MULTIPLIER: 2500,
  MOBILE_BREAKPOINT: 768,
  MAX_NOTIFICATIONS: 2,
};

const EXCLUDED_PATHS = ["/login", "/game", "/signup", "/forgot-password", "/reset-password", "/live-query-tool"];

const ToastMsg = ({ closeToast, toastProps, sentence = "" }) => (
  <div className="text-black">
    <table>
      <tr>
        <td>
          <BsAlarmFill style={{ color: "red", fontSize: "1.5rem", marginRight: ".8rem" }} />
        </td>
        <td className="text-white" style={{ fontSize: "1.2rem" }}>
          <span className="text-underline">Live Edge Update:</span> <strong> {sentence} </strong>
        </td>
      </tr>
    </table>
  </div>
);

const MyApp = ({ Component, pageProps, user, userDetail, token }) => {
  const context = useContext(AppContext);
  const [_user, _setUser] = useState(user);
  const foundNotificationsRef = useRef(false); // Add useRef for foundNotifications
  const intervalIdRef = useRef(null);
  const activeFiltersRef = useRef({});
  const emailDomain = useMemo(() => userDetail?.email?.split("@")[1]?.split(".")[0], [userDetail?.email]);
  const intervalId = useRef(null);
  const router = useRouter();
  const utmParams = useUTMParams();
  const isTrader = Utils.isTrader(userDetail);
  const reducer = (store, newStore) => {
    if (newStore === null) {
      localStorage.removeItem("store");
      return context;
    }
    return { ...store, ...newStore };
  };

  const getInitialStoreValue = () => {
    if (typeof window !== "undefined") {
      // Check if localStorage is available
      const storedValue = localStorage?.getItem("store");
      if (storedValue) {
        return JSON.parse(storedValue);
      }
    }
    return null; // Return a default value if localStorage is not available
  };

  const setModelSandboxFilterParams = (params) => {
    context.params = params;
  };

  const handleSummaryData = (thresholdValue, summaryData, overallSummaryData) => {
    context.thresholdValue = thresholdValue;
    context.summaryData = summaryData.filter(
      (option) => option.value_threshold === thresholdValue && option.bet_type !== "all"
    );
    context.overallSummaryData = overallSummaryData;
  };

  const [store, setStore] = useReducer(reducer, getInitialStoreValue() || context);

  // useEffect(() => {
  //   const version = localStorage.getItem("version");
  //   if (version !== process.env.NEXT_PROJECT_VERSION) {
  //     const isUserLoggedIn = user !== null;
  //     // localStorage.clear();
  //     destroyCookie({}, "access_token");
  //     destroyCookie({}, "token_access");
  //     destroyCookie({}, "name");
  //     localStorage.setItem("version", process.env.NEXT_PROJECT_VERSION);
  //     if (isUserLoggedIn) {
  //       router.push("/login#login");
  //     }
  //   }
  // }, []);

  // Commented out since user is passed to _user state from getInitialProps
  // useEffect(() => {
  //   _setUser(user ? user : null);
  // }, [user]);

  // Handle Route Changes and Popstate
  useEffect(() => {
    const handleRouteChange = (url) => {
      ga.pageview(url);
    };

    router.events.on("routeChangeComplete", handleRouteChange);
    window.addEventListener("popstate", () => router.push(window.location.href));

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
      window.removeEventListener("popstate", () => router.push(window.location.href));
    };
  }, [router.events]);

  // Memoized path check
  const isExcludedPath = useMemo(
    () => EXCLUDED_PATHS.some((path) => router.pathname.includes(path)),
    [router.pathname]
  );

  // Memoized screen check
  const isValidScreen = useMemo(
    () => typeof window !== "undefined" && window.innerWidth > NOTIFICATION_CONFIG.MOBILE_BREAKPOINT,
    []
  );

  const showToastNotification = useCallback(
    (league, game, index) => {
      if (!game?.count) return;

      setTimeout(() => {
        toast.error(<ToastMsg sentence={game.sentence} />, {
          icon: false,
          autoClose: NOTIFICATION_CONFIG.TOAST_DURATION,
          position: "bottom-right",
          theme: "dark",
          onClick: () => {
            redirectToPage(`/${league}/live-query-tool/live-notifications?qm=live&type=trader&org=${emailDomain}`);
          },
          style: { cursor: "pointer" },
        });
      }, (index + 1) * NOTIFICATION_CONFIG.TOAST_DELAY_MULTIPLIER);
    },
    [emailDomain]
  );

  const processLeagueData = useCallback(
    (leagueData, league) => {
      if (!leagueData) return;

      const edges = Object.values(leagueData)
        .sort((a, b) => b.count - a.count)
        .slice(0, NOTIFICATION_CONFIG.MAX_NOTIFICATIONS);

      edges.forEach((game, index) => {
        if (game?.count > 0) {
          foundNotificationsRef.current = true;
          showToastNotification(league, game, index);
        }
      });
    },
    [showToastNotification]
  );

  const fetchLiveQueries = useCallback(async () => {
    if (foundNotificationsRef.current || !activeFiltersRef.current) {
      return;
    }

    try {
      const response = await authPostRequest("/api/live-query/edges", token, {
        edges: activeFiltersRef.current,
        league: "wnba",
      });

      const leagues = ["nba", "wnba", "ncaa"];
      leagues.forEach((league) => {
        processLeagueData(response?.data?.data?.[league], league);
      });
    } catch (error) {
      console.error("Error fetching live queries:", error);
    }
  }, [token, processLeagueData]);

  const initializeFilters = useCallback(async () => {
    try {
      // Fetch notifications and filters in parallel
      const [notificationsResponse, filtersResponse] = await Promise.all([
        authGetRequest("/api/extras/shotquality_live_queries_notifications", token),
        authGetRequest("/api/extras/shotquality_live_queries", token),
      ]);

      const notifications = JSON.parse(notificationsResponse?.data?.data || '{"wnba":{}, "nba":{}, "ncaa":{}}');
      const filters = JSON.parse(filtersResponse?.data?.data || "{}");
      console.log("notifications", notifications);
      console.log("filters", filters);
      activeFiltersRef.current = getActiveSavedFilters(filters, notifications);
    } catch (error) {
      console.error("Error initializing filters:", error);
      activeFiltersRef.current = {};
    }
  }, [token]);

  useEffect(() => {
    if (!isTrader || !user || !isValidScreen || isExcludedPath) {
      return;
    }

    let isSubscribed = true;

    const startPolling = async () => {
      await initializeFilters();

      if (!isSubscribed) return;

      // Initial fetch
      await fetchLiveQueries();

      // Start polling
      intervalIdRef.current = setInterval(fetchLiveQueries, NOTIFICATION_CONFIG.POLLING_INTERVAL);
    };

    startPolling();

    // Cleanup function
    return () => {
      isSubscribed = false;
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      }
    };
  }, [isTrader, user, isValidScreen, isExcludedPath, initializeFilters, fetchLiveQueries]);

  // const fetchLiveQueries = (activeFilters) => {
  //   if (foundNotificationsRef.current) {
  //     clearInterval(intervalId.current);
  //     return;
  //   }
  //   if (activeFilters) {
  //     authPostRequest(`/api/live-query/edges`, token, {
  //       edges: activeFilters,
  //       league: "wnba",
  //     })
  //       .then((response) => response.data)
  //       .then((response) => {
  //         const leagues = ["nba", "wnba", "ncaa"];
  //         leagues.forEach((league) => {
  //           if (!response?.data?.[league]) {
  //             return;
  //           }
  //           let edges = Object.keys(response?.data?.[league]).map((key) => response.data[league][key]);
  //           edges.sort((a, b) => b.count - a.count);
  //           let top2Edges = edges.slice(0, 2);
  //           top2Edges.forEach((game, index) => {
  //             if (game?.count === 0) return;
  //             foundNotificationsRef.current = true; // Update the ref
  //             setTimeout(() => {
  //               toast.error(<ToastMsg sentence={game.sentence} />, {
  //                 icon: false,
  //                 autoClose: 7000,
  //                 position: "bottom-right",
  //                 theme: "dark",
  //                 onClick: (event) => {
  //                   redirectToPage(
  //                     `/${league}/live-query-tool/live-notifications?qm=live&type=trader&org=${emailDomain}`
  //                   );
  //                 },
  //                 style: { cursor: "pointer" },
  //               });
  //             }, (index + 1) * 2500);
  //           });
  //         });
  //       })
  //       .catch((err) => {
  //         console.log("err", err);
  //       });
  //   }
  // };

  // useEffect(() => {
  //   const isNotMobileScreen = window.innerWidth > 768;
  //   if (
  //     _user &&
  //     isTrader &&
  //     isNotMobileScreen &&
  //     !router.pathname.match(/\/login|\/game|\/signup|\/forgot-password|\/reset-password|\/live-query-tool/)
  //   ) {
  //     let tempSavedFiltersNotifications = { wnba: {}, nba: {}, ncaa: {} };
  //     let activeFilters = {};
  //     const fetchNotifications = async () => {
  //       try {
  //         const response = await authGetRequest(`/api/extras/shotquality_live_queries_notifications`, token);
  //         if (response?.data?.data) {
  //           const parsedData = JSON.parse(response.data.data);
  //           tempSavedFiltersNotifications = parsedData;
  //         }
  //       } catch (err) {
  //         console.error("Error fetching notifications:", err);
  //       }
  //     };

  //     const fetchFilters = async () => {
  //       try {
  //         const response = await authGetRequest(`/api/extras/shotquality_live_queries`, token);
  //         if (response?.data?.data) {
  //           const parsedData = JSON.parse(response.data.data);
  //           activeFilters = getActiveSavedFilters(parsedData, tempSavedFiltersNotifications);
  //         }
  //       } catch (err) {
  //         console.error("Error fetching filters:", err);
  //       }
  //     };
  //     const initialize = async () => {
  //       await Promise.all([fetchNotifications(), fetchFilters()]);
  //     };

  //     initialize();

  //     fetchLiveQueries(activeFilters);
  //     intervalId.current = setInterval(fetchLiveQueries(activeFilters), 20000);
  //     return () => clearInterval(intervalId.current);
  //   }
  // }, [isTrader, _user]);

  const redirectToPage = (link) => {
    router.push(link);
  };

  return (
    <>
      {/* <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_GOOGLE_ANALYTICS}`}
      /> */}
      <Script strategy="afterInteractive" id="google-analytics-script">
        {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${process.env.NEXT_GOOGLE_ANALYTICS}', {
      page_path: window.location.pathname,
      });
  `}
      </Script>
      <PlausibleProvider domain="shotqualitybets.com">
        <AppContextWrapper
          state={{
            store,
            setStore,
            setModelSandboxFilterParams,
            context,
            handleSummaryData,
            user: _user,
            userDetail: userDetail,
          }}
        >
          <Head>
            <meta charSet="utf-8" />
            <meta name="google-site-verification" content="EMglQMoVUkn-CAHBbODcnii7_Vw0MBVOICnB56wCji4" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="theme-color" content="#000000" />
            <title>ShotQualityBets</title>
          </Head>
          <CookiesProvider>
            <ApolloProvider client={client}>
              <Component {...pageProps} />
            </ApolloProvider>
          </CookiesProvider>
        </AppContextWrapper>
      </PlausibleProvider>
    </>
  );
};

MyApp.getInitialProps = async ({ ctx }) => {
  const appService = await getAppService(ctx);
  const user = appService.getUser();
  let userDetail = null;
  let token = null;
  if (user) {
    token = appService.getToken();
    userDetail = await appService.get("api/account");
  }
  return { user, userDetail, token };
};

export default MyApp;
