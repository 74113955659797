import React from "react";

export function Track({ source, target, getTrackProps }) {
  return (
    <div
      style={{
        position: "absolute",
        height: 15,
        zIndex: 1,
        marginTop: 35,
        backgroundColor: "#d07c3b",
        borderRadius: 5,
        cursor: "pointer",
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`,
      }}
      {...getTrackProps()}
    />
  );
}

export function Handle({ handle: { id, value, percent }, getHandleProps, divId }) {
  const formatFloatValue = (value) => {
    if (isNaN(value) || value === "") {
      return value;
    }
    if (!Number.isInteger(value)) {
      // const valueStr = value.toFixed(2);
      return parseFloat(value);
    }
    return value;
  };
  return (
    <div
      style={{
        left: `${percent}%`,
        position: "absolute",
        boxSizing: "content-box",
        marginLeft: -9,
        marginTop: 10,
        zIndex: 2,
        width: 6,
        height: 6,
        content: "A",
        textAlign: "center",
        cursor: "pointer",
        border: "6px solid #F26B0B",
        borderRadius: "50%",
        backgroundColor: "#fff",
      }}
      id={divId}
      {...getHandleProps(id)}
    >
      <span>
        <h4 id={`${divId}-h4`} className="live-query-handle">
          {/* {value} */}
          {value ? formatFloatValue(value) : value}
        </h4>
      </span>
    </div>
  );
}

export function Tick({ tick, count }) {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          marginTop: 52,
          marginLeft: -0.5,
          width: 1,
          height: 8,
          backgroundColor: "silver",
          left: `${tick.percent}%`,
        }}
      />
      <div
        style={{
          position: "absolute",
          marginTop: 60,
          fontSize: 10,
          textAlign: "center",
          marginLeft: `${-(100 / count) / 2}%`,
          width: `${100 / count}%`,
          left: `${tick.percent}%`,
        }}
      >
        {tick.value}
      </div>
    </div>
  );
}

export const sliderStyle = {
  // Give the slider some width
  position: "relative",
  width: "100%",
  height: 50,
  zIndex: 0,
};

export const railStyle = {
  position: "absolute",
  width: "100%",
  height: 6,
  marginTop: 16,
  borderRadius: 5,
  backgroundColor: "#FBCEAE",
};
