import React, { useState, useEffect } from "react";
import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";
import { Track, Handle, Tick, sliderStyle, railStyle } from "./SliderUtil";
const tickStyle = {
  position: "absolute",
  marginTop: 14,
  marginLeft: -0.5,
  width: 1,
  height: 5,
  backgroundColor: "silver",
  pointerEvents: "none",
};
const SliderRangeDualFloat = (props) => {
  const [sliderVal, setSliderVal] = useState([
    props.startingMinValue ?? props.min,
    props.startingMaxValue ?? props.max,
  ]);

  const handleSliderChange = (e) => {
    const min = props?.step ? e[0].toFixed(2) : e[0];
    const max = props?.step ? e[1].toFixed(2) : e[1];
    if (props.disableSlider === "right") {
      console.log("called", min, props.max);
      setSliderVal([min, props.max]);
    } else if (props.disableSlider === "left") {
      setSliderVal([props.min, max]);
    } else {
      setSliderVal([min, max]);
    }
  };

  const onUpdate = (e) => {
    if (props.disableSlider) {
      return;
    }
    const minElement = document.getElementById(`${props.id.replaceAll(" ", "")}-0`);
    const minSpanElement = document.getElementById(`${props.id.replaceAll(" ", "")}-0-h4`);
    const maxElement = document.getElementById(`${props.id.replaceAll(" ", "")}-1`);
    const maxSpanElement = document.getElementById(`${props.id.replaceAll(" ", "")}-1-h4`);
    if (!minElement || !maxElement) {
      return;
    }
    const minLeft = parseFloat(minElement.style.left?.replaceAll("%", ""));
    const maxLeft = parseFloat(maxElement.style.left?.replaceAll("%", ""));

    minElement.style.transform = `rotate(0deg)`;
    minSpanElement.style.transform = `rotate(0deg)`;
    if (Math.abs(minLeft - maxLeft) < 15) {
      maxElement.style.transform = `rotate(180deg)`;
      maxSpanElement.style.transform = `rotate(180deg)`;
    } else {
      maxElement.style.transform = `rotate(0deg)`;
      maxSpanElement.style.transform = `rotate(0deg)`;
    }
  };

  useEffect(() => {
    // setSliderVal([20, 0])
    setSliderVal([props.startingMinValue ?? props.min, props.startingMaxValue ?? props.max]);
  }, [props.max, props.min, props.resetFilters]);

  return (
    <form action="">
      <div className="form-group">
        {props.title !== undefined ? (
          <div
            style={{
              fontSize: 12,
              fontWeight: 500,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span>{sliderVal[0]}</span>
            <span>{[props.title]}</span>
            <span>{sliderVal[1]}</span>
          </div>
        ) : null}
        <div className="sliderRange-dual">
          <Slider
            rootStyle={sliderStyle}
            domain={[props.min, props.max]}
            step={props.step || 1}
            values={sliderVal}
            // reversed
            onChange={handleSliderChange}
            onUpdate={onUpdate}
            onSlideEnd={props.handleChangeComplete}
          >
            <Rail>{({ getRailProps }) => <div style={railStyle} {...getRailProps()} />}</Rail>
            <Handles>
              {({ handles, getHandleProps }) => {
                return (
                  <div className="slider-handles">
                    {handles.map((handle, i) => {
                      let newValue = props?.step && props.step < 1 ? handle.value.toFixed(2) : handle.value;
                      newValue = props.formatHandle ? props.formatHandle(newValue) : newValue;
                      const newHandle = {
                        ...handle,
                        value: newValue,
                      };
                      const handleDisable =
                        props.disableSlider === "left" ? i === 0 : props.disableSlider === "right" ? i === 1 : false;
                      if (handleDisable) return null;
                      return (
                        <Handle
                          divId={`${props.id.replaceAll(" ", "")}-${i}`}
                          key={handle.id}
                          handle={newHandle}
                          getHandleProps={getHandleProps}
                        />
                      );
                    })}
                  </div>
                );
              }}
            </Handles>
            <Ticks count={props.ticks || 5} values={props.ticksValues}>
              {({ ticks }) => (
                <div className="slider-ticks">
                  {ticks.map((tick, idx) => (
                    <div
                      key={tick.id}
                      style={{
                        ...tickStyle,
                        left: `${tick.percent}%`,
                      }}
                    >
                      {props.formatValue ? (
                        <p
                          style={{
                            paddingTop: " 0.4rem",
                            fontSize: "0.8rem",
                            position: "absolute",
                            width: "1.6rem",
                            left: "-0.75rem",
                            textAlign: "center",
                          }}
                        >
                          {ticks[0].value < 0 && tick.value > 0 ? "+" : ""}
                          {props.formatValue(tick.value, idx)}
                        </p>
                      ) : (
                        <p
                          style={{
                            paddingTop: " 0.4rem",
                            fontSize: "0.8rem",
                            position: "absolute",
                            width: "1.6rem",
                            left: "-0.75rem",
                            textAlign: "center",
                          }}
                        >
                          {ticks[0].value < 0 && tick.value > 0 ? "+" : ""}
                          {tick.value}
                        </p>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </Ticks>
            {/* <Tracks left={false} right={false}>
              {({ tracks, getTrackProps }) => (
                <div className='slider-tracks'>
                  {tracks.map(({ id, source, target }) => (
                    <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
                  ))}
                </div>
              )}
            </Tracks> */}
          </Slider>
        </div>
      </div>
    </form>
  );
};

export default SliderRangeDualFloat;
