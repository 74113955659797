import React from "react";
import { useState, useRef } from "react";

const Tooltip = ({ text, classNames = "", styles = {}, label = null, delay = 0 }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const timeoutRef = useRef(null);

  const handleMouseEnter = () => {
    timeoutRef.current = setTimeout(() => {
      setShowTooltip(true);
    }, delay);
  };

  const handleMouseLeave = () => {
    clearTimeout(timeoutRef.current);
    setShowTooltip(false);
  };

  return (
    <div className={`gen-tooltip`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {label ? label : <span>&#9432;</span>}
      {showTooltip && (
        <span className={`tooltip-text ${classNames}`} style={{ ...styles }}>
          {text}
        </span>
      )}
    </div>
  );
};

export default Tooltip;
