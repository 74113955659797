import { defGameClockDuration } from "../components/LiveQueryConstants";

function pad(number) {
  return number < 10 ? `0${number}` : number;
}

export const generateGameClockChoicesNcaa = (totalSeconds) => {
  const halfDuration = 1200; // 20 minutes in seconds
  const overtimeDuration = 300; // 5 minutes in seconds
  let choices = [];
  let remainingSeconds = totalSeconds;
  let currentHalf = 1;

  // Generate choices for the first and second half
  for (let i = 0; i < 2; i++) {
    let halfChoices = []; // Temporary storage for the current half to reverse labels
    for (let j = 0, i = halfDuration; j <= halfDuration; j += 60, i -= 60) {
      let minutes = Math.floor(j / 60);
      let seconds = j % 60;
      let label = `${currentHalf}H ${pad(minutes)}:${pad(seconds)}`;
      let value = remainingSeconds - i;
      let obj = {};
      obj[label] = value;
      halfChoices.push(obj);
    }
    remainingSeconds -= halfDuration;
    currentHalf++;
    // Reverse the labels for each half to correct their order
    choices = choices.concat(halfChoices.reverse());
  }
  let otTimes = 1;
  // Generate choices for overtime, if any
  while (remainingSeconds > 0) {
    let OTChoices = [];

    for (let j = 0, i = remainingSeconds, k = overtimeDuration; j <= overtimeDuration; j += 60, i -= 60, k -= 60) {
      let minutes = Math.floor(k / 60);
      let seconds = j % 60;
      let label = `OT ${otTimes} ${pad(minutes)}:${pad(seconds)}`;
      let value = i;
      let obj = {};
      obj[label] = value;
      OTChoices.push(obj);
    }
    choices = choices.concat(OTChoices);
    otTimes += 1;
    remainingSeconds -= overtimeDuration;
  }
  return choices;
};
export const generateGameClockChoicesNBA = (totalSeconds) => {
  const quarterDuration = 720; // 12 minutes in seconds
  const overtimeDuration = 300; // 5 minutes in seconds
  let choices = [];
  let remainingSeconds = totalSeconds;
  let currentQuarter = 1;

  // Generate choices for the four quarters
  for (let i = 0; i < 4; i++) {
    let quarterChoices = []; // Temporary storage for the current quarter to reverse labels
    for (let j = 0, k = quarterDuration; j <= quarterDuration; j += 60, k -= 60) {
      let minutes = Math.floor(j / 60);
      let seconds = j % 60;
      let label = `${currentQuarter}Q ${pad(minutes)}:${pad(seconds)}`;
      let value = remainingSeconds - k;
      let obj = {};
      obj[label] = value;
      quarterChoices.push(obj);
    }
    remainingSeconds -= quarterDuration;
    currentQuarter++;
    // Reverse the labels for each quarter to correct their order
    choices = choices.concat(quarterChoices.reverse());
  }

  let otTimes = 1;
  // Generate choices for overtime, if any
  while (remainingSeconds > 0) {
    let OTChoices = [];

    for (let j = 0, i = remainingSeconds, k = overtimeDuration; j <= overtimeDuration; j += 60, i -= 60, k -= 60) {
      let minutes = Math.floor(k / 60);
      let seconds = j % 60;
      let label = `OT ${otTimes} ${pad(minutes)}:${pad(seconds)}`;
      let value = i;
      let obj = {};
      obj[label] = value;
      OTChoices.push(obj);
    }
    choices = choices.concat(OTChoices);
    otTimes += 1;
    remainingSeconds -= overtimeDuration;
  }
  return choices;
};
export const generateGameClockChoicesWNBA = (totalSeconds) => {
  const quarterDuration = 600; // 10 minutes in seconds
  const overtimeDuration = 300; // 5 minutes in seconds
  let choices = [];
  let remainingSeconds = totalSeconds;
  let currentQuarter = 1;

  // Generate choices for the four quarters
  for (let i = 0; i < 4; i++) {
    let quarterChoices = []; // Temporary storage for the current quarter to reverse labels
    for (let j = 0, k = quarterDuration; j <= quarterDuration; j += 60, k -= 60) {
      let minutes = Math.floor(j / 60);
      let seconds = j % 60;
      let label = `${currentQuarter}Q ${pad(minutes)}:${pad(seconds)}`;
      let value = remainingSeconds - k;
      let obj = {};
      obj[label] = value;
      quarterChoices.push(obj);
    }
    remainingSeconds -= quarterDuration;
    currentQuarter++;
    // Reverse the labels for each quarter to correct their order
    choices = choices.concat(quarterChoices.reverse());
  }

  let otTimes = 1;
  // Generate choices for overtime, if any
  while (remainingSeconds > 0) {
    let OTChoices = [];

    for (let j = 0, i = remainingSeconds, k = overtimeDuration; j <= overtimeDuration; j += 60, i -= 60, k -= 60) {
      let minutes = Math.floor(k / 60);
      let seconds = j % 60;
      let label = `OT ${otTimes} ${pad(minutes)}:${pad(seconds)}`;
      let value = i;
      let obj = {};
      obj[label] = value;
      OTChoices.push(obj);
    }
    choices = choices.concat(OTChoices);
    otTimes += 1;
    remainingSeconds -= overtimeDuration;
  }
  return choices;
};

export const generateGameClockChoices = (league) => {
  let choices = [];
  let gameClockChoices = {};
  let gameClockChoicesKeys = [];
  if (league === "nba" || league === "NBA") {
    choices = generateGameClockChoicesNBA(defGameClockDuration[league]);
  } else if (league === "WNBA" || league === "wnba" || league === "college_womens") {
    choices = generateGameClockChoicesWNBA(defGameClockDuration[league]);
  } else if (league === "NCAA" || league === "ncaa" || league === "college_mens") {
    choices = generateGameClockChoicesNcaa(defGameClockDuration[league]);
  } else {
    console.log("Invalid league type", league);
    choices = [];
  }
  choices.forEach((choice) => {
    let choiceKey = Object.keys(choice)[0];
    let choiceValue = Object.values(choice)[0];
    gameClockChoicesKeys.push(choiceValue);
    gameClockChoices[choiceValue] = choiceKey;
  });
  return { gameClockChoicesKeys, gameClockChoices };
};
